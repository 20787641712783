import { navigate } from "gatsby";
import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Signin from "../components/signin";
import PhoneCodeType from "../types/phoneCodeType";

const SigninPage: FC = () => (
  <Layout header menuState="profile">
    <SEO title="Accueil" />
    <Signin
      goToCodeValidation={(type: PhoneCodeType): Promise<void> =>
        navigate(`/validate-code?type=${type}`)
      }
      onLogin={(): Promise<void> => navigate("/cart")}
    />
  </Layout>
);

export default SigninPage;
